<template>
  <div class="relative home">
    <a href="https://wa.me/6596139068" target="_blank" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-white rounded-full"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z" fill="rgba(35,205,100,1)"/></svg></a>
    <header class="py-4 bg-white">
      <div class="items-center justify-between w-full max-w-screen-lg px-4 mx-auto md:flex">
        <h1 class="logo">
          <img class="mx-auto mb-4 md:mb-0" src="images/logo.png">
        </h1>
        <div class="flex flex-col items-center text-center">
          <p class="text-xs text-gray-800 lg:text-sm">Call us for an appointment</p>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z" fill="rgba(195,0,190,1)"/></svg>
            <p class="font-semibold lg:text-xl text-siteViolet"><a href="tel:+6596139068">9613 9068</a> / <a href="tel:+6583466231">8346 6231</a></p>
          </div>
        </div>
      </div>
    </header>
    <section class="relative py-16 overflow-hidden bg-gray-200 bg-center bg-cover bg-header-mobile lg:py-24">
      <div class="absolute top-0 right-0 z-10 hidden w-3/5 h-full bg-center bg-no-repeat bg-contain opacity-50 lg:block bg-header xl:opacity-100"></div>
      <div class="relative z-30 flex items-end justify-between w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap w-full overflow-hidden text-center lg:text-left">
          <div class="w-full overflow-hidden text-gray-800 lg:w-3/5">
            <h2 class="mb-2 text-4xl font-semibold leading-none uppercase text-siteViolet lg:text-6xl lg:font-medium">Looking for Professional <br class="hidden lg:block">House Painter?</h2>
            <h4 class="mb-6 text-xl lg:text-3xl lg:mb-8">Making Your Home Beautiful, Inside & Out</h4>
            <p class="text-sm lg:text-base">Committed to top quality workmanship, professionalism, <br class="hidden lg:block">and customer satisfaction. Give your home a beautiful new look.</p>
            <div class="flex flex-wrap justify-center mt-8 text-center circles lg:mt-10 lg:justify-start">
              <div class="flex items-center justify-center px-3 py-6 mx-3 text-white bg-center bg-cover rounded-full lg:p-4 bg-sitePurple">
                <p class="font-semibold leading-tight lg:text-lg">Over <span class="block text-xl lg:text-2xl">10 Years</span> Experience</p>
              </div>
              <div class="flex items-center justify-center px-3 py-6 mx-3 text-white bg-center bg-cover rounded-full lg:p-4 bg-sitePurple">
                <p class="font-semibold leading-tight lg:text-lg">Proven Painting Results</p>
              </div>
            </div>
          </div>
          <div class="w-full overflow-hidden lg:w-2/5">
            <!-- Column Content -->
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 text-white bg-siteViolet lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <div class="p-4 border-4 border-white">
          <div class="flex items-center justify-center lg:items-end">
            <div class="mr-4"><img src="images/icon-technician.png"></div>
            <div>
              <h4 class="text-lg leading-none lg:text-xl">Call us today</h4>
              <p class="text-xl lg:text-2xl"><a href="tel:+6596139068">+65 9613 9068</a> / <a href="tel:+6583466231">8346 6231</a></p>
              <p class="text-sm leading-tight lg:text-base">to schedule an appointment</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 text-center lg:py-16">
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <h3 class="mb-1 text-3xl font-semibold leading-tight uppercase lg:text-4xl">Our Painting Services</h3>
        <p class="mb-4 text-sm lg:text-base">All house painting services will be carefully tailor-made to suit each individual house, depending on the size & painting requirement.</p>
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden lg:w-1/3">
            <img src="images/service-01.png"/>
            <h4 class="w-full px-4 py-3 text-lg font-medium text-white bg-gray-800 lg:text-xl">For HDB Owners</h4>
            <div class="p-4 text-white bg-siteViolet">
              <h4 class="mb-2 text-xl leading-none lg:text-2xl">As low as</h4>
              <h3 class="text-4xl font-semibold leading-none lg:text-5xl">$498</h3>
            </div>
            <div class="px-4 py-6 bg-gray-200">
              <ul class="pl-5 text-left list-disc">
                <li class="mb-2 text-sm">Pricing varies depending on size</li>
                <li class="mb-2 text-sm">FOC shifting of furniture</li>
                <li class="mb-2 text-sm">FOC furniture protection</li>
                <li class="mb-2 text-sm">FOC post-painting cleaning</li>
                <li class="text-sm">1 year warranty</li>
              </ul>
            </div>
          </div>
          <div class="w-full px-4 my-4 overflow-hidden lg:w-1/3">
            <img src="images/service-02.png"/>
            <h4 class="w-full px-4 py-3 text-lg font-medium text-white bg-gray-800 lg:text-xl">For Condo Owners</h4>
            <div class="p-4 text-white bg-siteViolet">
              <h4 class="mb-2 text-xl leading-none lg:text-2xl">As low as</h4>
              <h3 class="text-4xl font-semibold leading-none lg:text-5xl">$498</h3>
            </div>
            <div class="px-4 py-6 bg-gray-200">
              <ul class="pl-5 text-left list-disc">
                <li class="mb-2 text-sm">Pricing varies depending on size</li>
                <li class="mb-2 text-sm">FOC shifting of furniture</li>
                <li class="mb-2 text-sm">FOC furniture protection</li>
                <li class="mb-2 text-sm">FOC post-painting cleaning</li>
                <li class="text-sm">1 year warranty</li>
              </ul>
            </div>
          </div>
          <div class="w-full px-4 my-4 overflow-hidden lg:w-1/3">
            <img src="images/service-03.png"/>
            <h4 class="w-full px-4 py-3 text-lg font-medium text-white bg-gray-800 lg:text-xl">For Landed Owners</h4>
            <div class="p-4 text-white bg-siteViolet">
              <h4 class="mb-2 text-xl leading-none lg:text-2xl">As low as</h4>
              <h3 class="text-4xl font-semibold leading-none lg:text-5xl">$1,988</h3>
            </div>
            <div class="px-4 py-6 bg-gray-200">
              <ul class="pl-5 text-left list-disc">
                <li class="mb-2 text-sm">Pricing varies depending on size</li>
                <li class="mb-2 text-sm">FOC shifting of furniture</li>
                <li class="mb-2 text-sm">FOC furniture protection</li>
                <li class="mb-2 text-sm">FOC post-painting cleaning</li>
                <li class="text-sm">1 year warranty</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="relative py-12 pt-0 text-white bg-sitePurple lg:py-16">
      <div class="relative top-0 mb-12 bg-center bg-cover lg:mb-0 bg-01 lg:absolute lg:w-1/2 lg:h-full"></div>
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden lg:w-1/2">
          <!-- Column Content -->
          </div>
          <div class="w-full px-4 overflow-hidden lg:w-1/2">
            <h3 class="mb-1 text-3xl font-semibold leading-tight text-center uppercase lg:text-4xl lg:text-left">Get One Step Closer to <br class="hidden lg:block">Your Dream House</h3>
            <p class="mb-8 font-medium lg:text-lg">Call Us At <a href="tel:+6596139068">9613 9068</a> / <a href="tel:+6583466231">8346 6231</a> for a Free Site-Inspection Today!</p>
            <div class="enquiry-form">
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5830915.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 lg:py-16">
      <div class="w-full max-w-screen-md px-4 mx-auto">
        <h3 class="mb-8 text-3xl font-semibold leading-tight text-center text-gray-800 uppercase lg:text-4xl">Testimonials</h3>
        <carousel :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="false" pagination-padding="10" pagination-active-color="#2d05a0">
          <slide class="text-center">
            <p class="mb-4"><i>"They came down to my apartment & have a site inspection, without taking any money from me, even I told them I will consider first."</i></p>
            <p class="mb-4"><i>"In the end, I get them because they friendly & the price is reasonable."</i></p>
            <p><b>- Jacky Teo, (Condo)</b></p>
          </slide>
          <slide class="text-center">
            <p class="mb-4"><i>"They really try to understand what we want & it turns out exactly what we have in mind."</i></p>
            <p><b>- Lee YS, (Semi-D)</b></p>
          </slide>
          <slide class="text-center">
            <p class="mb-4"><i>"I never expect they will do well at first, but they really shows me the confident & true enough! They did a wonderful job, look intro to my friend too."</i></p>
            <p><b>- Lynn Tan, (HDB)</b></p>
          </slide>
        </carousel>
      </div>
    </section>
    <section class="relative py-12 pt-0 bg-gray-200 lg:py-16">
      <div class="relative top-0 mb-12 bg-center bg-cover -r-1 lg:mb-0 bg-03 lg:absolute lg:w-1/2 lg:h-full"></div>
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap items-center -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden md:w-1/3 lg:w-1/2">
          </div>
          <div class="w-full px-4 overflow-hidden text-gray-800 lg:pl-8 md:w-2/3 lg:w-1/2">
            <h4 class="text-2xl font-semibold leading-none uppercase">About</h4>
            <h3 class="mb-6 text-3xl font-semibold leading-tight uppercase lg:text-4xl">HMS Painting Services</h3>
            <p class="mb-6 text-sm">We have been in the house painting business for over 10 years & we really enjoy it a lot.</p>
            <p class="mb-6 text-sm">We also help our customers to paint their dream house with quality paint & professional painting skills.</p>
            <p class="mb-6 text-sm">In addition, we also provide handyman works, as usually requested by our customers to make their house completed.</p>
            <p class="mb-2 text-sm font-semibold">Our Promises:</p>
            <ul class="pl-5 text-left list-disc">
              <li class="mb-2 text-sm">Prompt, same-day response</li>
              <li class="mb-2 text-sm">Attentive, thorough free site inspection</li>
              <li class="mb-2 text-sm">Honest, transparent pricing & no hidden cost</li>
              <li class="mb-2 text-sm">Effective, quality painting result</li>
              <li class="text-sm">Sincere, friendly & understanding</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 text-white bg-siteViolet lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <div class="p-4 border-4 border-white">
          <div class="flex items-center justify-center lg:items-end">
            <div class="mr-4"><img src="images/icon-technician.png"></div>
            <div>
              <h4 class="text-lg leading-none lg:text-xl">Call us today</h4>
              <p class="text-xl lg:text-2xl"><a href="tel:+6596139068">+65 9613 9068</a> / <a href="tel:+6583466231">+65 8346 6231</a></p>
              <p class="text-sm leading-tight lg:text-base">to schedule an appointment</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="relative py-12 pt-0 text-white bg-sitePurple lg:py-16">
      <div class="relative top-0 mb-12 bg-center bg-cover lg:mb-0 bg-02 lg:absolute lg:w-1/2 lg:h-full"></div>
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden lg:w-1/2">
            <!-- Column Content -->
          </div>
          <div class="w-full px-4 overflow-hidden lg:w-1/2">
            <h3 class="mb-1 text-3xl font-semibold leading-tight text-center uppercase lg:text-4xl lg:text-left">Get Your Free <br class="hidden lg:block">Site Inspection Now</h3>
            <p class="mb-8 font-medium lg:text-lg">With an Accurate Assessment by Our Trained <br class="hidden lg:block">Painting Professionals</p>
            <div class="enquiry-form">
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5830915.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="py-12 text-center text-gray-800 lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <h1 class="logo">
          <img class="mx-auto mb-8" src="images/logo.png">
          <h6 class="font-semibold uppercase lg:text-lg">HMS Painting Services</h6>
          <p class="mb-8 text-sm">Singapore Islandwide Services</p>
          <p class="mb-8 text-sm">
            <span class="font-semibold">Hotline:</span><br>
            <a href="tel:+6596139068" class="text-siteViolet">+65 9613 9068</a> / 
            <a href="tel:+6583466231" class="text-siteViolet">+65 8346 6231</a><br>
          </p>
          <p class="text-sm">
            <span class="font-semibold">Operational Hours:</span><br>
            Mon – Sun: 9am to 6pm<br>
            Daily Operation
          </p>
        </h1>
      </div>
    </footer>
  </div>
</template>

<style>
  .bg-header-mobile {
    background-image: url(/images/bg-header-mobile.png);
  }
  .bg-header {
    background-image: url(/images/bg-header.png);
  }
  .bg-01, .bg-02 {
    min-height: 400px;
  }
  .bg-01 {
    background-image: url(/images/bg-01.jpg);
  }
  .bg-02 {
    background-image: url(/images/bg-02.jpg);
  }
  .bg-03 {
    background-image: url(/images/bg-03.jpg);
  }
  .icon-solution, .icon-services  {
    width: 60px;
  }
  .circles div {
    width: 140px;
    height: 140px;
  }
  .enquiry-form iframe {
    height: 430px !important;
  }
  @media (min-width: 640px) {
    .icon-solution, .icon-services   {
      width: 80px;
    }
    .circles div {
      width: 160px;
      height: 160px;
    }
  }
  @media (min-width: 1024px) {
    .bg-header-mobile {
      background-image: none;
    }
    .bg-01, .bg-02 {
      left: -16px;
    }
  }
</style>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import { CollapseTransition } from 'vue2-transitions'
  export default {
    components: {
      CollapseTransition,
      Carousel,
      Slide
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>